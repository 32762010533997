/* 
    Created on : Sep 25, 2018, 9:49:18 AM
    Author     : Horatiu Cristea-Lubinschi <horatiu.cristea@gmail.com>
*/

.gray-section {
	background: $gray-background;
	border-top: 1px solid $gray-border;
	border-bottom: 1px solid $gray-border;
}

.index-section {

	padding: 100px 0 120px;

	header {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			bottom: -50px;
			height: 1px;
			width: 85%;
			left: 0;
			right: 0;
			margin: auto;
			background: #eaeaea;
		}

		h3 {
			text-align: center;
			font-family: $nunito;
			font-size: 26px;
			font-weight: bold;
			color: #555;
		}

		p {
			color: #333;
			font-size: 15px;
			text-align: center;
			line-height: 25px;
			width: 75%;
			margin: 0 auto;
			margin-top: 25px;
		}
	}

}

.index-contact {
	
	header {
		&:after {
			display: none;
		}
	}

	form {
		width: 550px;
		margin: 40px auto 0;

		@media(max-width: 767px) {
			width: 100%;
		}

		h3 {
			font-size: 12px;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: .5px;
			margin-top: 40px;
		}

		p {
			margin-bottom: -5px;
			color: #5f748c;
		}

		input[type="text"],
		input[type="email"],
		textarea {
			font-size: 17px;
			margin-top: 20px;
			outline: none;
			/*color: #666;*/
			background: white;
			padding: 12px 20px;
			/*border: 1px solid #cbcdd2;*/
			width: 100%;
			border-radius: 4px;

			@include transition(all .2s);

			@include placeholder {
				color: #B8BBC7;
			}

			@media(max-width: 767px) {
				padding: 15px 20px;
				font-size: 16px;
			}
		}

		textarea {
			font-size: 16px;
		}
		
		button.btn {
			width: 100%;
			padding: 18px 20px;
			margin: 23px 0;
			font-weight: 500;
			font-size: 18px;
			color: #fff;
			text-shadow: 1px 1px 0px rgba(106, 132, 158, 0.6);
			border-radius: 55px;

			@include transition(all .2s);

			&:hover {
				color: #fff;
			}
		}
		
		input[name="frm[email]"] {
			display: none;
		}
	}

}

.index-timeline {
	
	padding-bottom: 50px;
	
	header {
		
		&:after {
			background: transparent;
		}
		
	}
}


.timeline-wrapper {
	
	margin-top: 0px;
	
	&:before {
		background-color: $orange;
	}
	
	.year {
		
		span {
			background-color: $orange;
			color: #fafafa;
		}
		
	}
	
	.node {
		
		&:before {
			background-color: $orange;
		}
		
		.marker {
			background-color: $orange;
			border: 3px solid #f7d9bf;
		}
		
		.news {
			border: 3px solid $orange;
			box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);

			section {
				
				p {
					color: #000;
				}
			
			}
			
		}
		
	}

}

.footer {
	
	.bottom {
		border-top: none;
		
		ul {
			
			li {
				@media(max-width: 767px) {
					display: block;
				}
			}
			
			a {
				
				font-size: 14px;
				
				&:after {
					@media(max-width: 767px) {
						content: ''
					}
				}
				
			}
		}
		
	}
	
}